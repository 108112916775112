.step-control {
  @include media-breakpoint-up(sm) {
    text-align: right;
  }

  .step-control__next {
    @include media-breakpoint-down(md) {
      width: 100%;
    }

    // TODO: Styles for different status
    background-color: #333;
    color: #fff;
    text-decoration: none;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
