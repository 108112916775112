.simple-tile {
  position: relative;

  @include hoverScaleImage();
}

.simple-tile__image {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-color: rgba($black, 0.15);
  }
}

.simple-tile__title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  color: $white;

  @include heading-three;
}
