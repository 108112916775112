.summary-card {
  .summary-card__item {
    &:first-of-type {
      border-top: none;
      padding-top: 0;
    }
  }
}

.summary-card__name,
.summary-card__total {
  font-weight: 700;
}

.summary-card__guest,
.summary-card__flex-item {
  display: flex;
  justify-content: space-between;
}

.summary-card__action {
  cursor: pointer;
  margin-left: 0.5rem;
  color: #9d9d9d;
}

.summary-card__date,
.summary-card__deposit,
.summary-card__balance {
  @include text-small;
}

.summary-card__deposit,
.summary-card__balance {
  font-weight: 600;
}

.summary-card__due-date {
  font-weight: 400;
}
