@use "sass:map";

$header-bg-colour: $primary !default;
$header-text-color: $text-white !default;
$header-menu-button-bg-colour: rgba($black, 0.1) !default;
$header-menu-button-text-color: $text-white !default;
$header-main-shadow: none !default;
$header-mobile-menu-border-top: none !default;

.header {
  position: relative;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0);
  transition: box-shadow 0.25s;

  &.header--mobile-nav-active,
  &.header--mobile-search-active {
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.33);
    z-index: map.get($zindex, header-mobile-active);
  }

  &--sticky {
    position: sticky;
    top: 0;
    z-index: map.get($zindex, sticky-nav);
  }
}

// Header top

.header__top {
  background: $bt-charcoal;
  color: $text-white;
  position: relative;
  z-index: 1;

  a,
  button {
    color: $text-white;
    text-decoration: none;
  }

  .dropdown-menu {
    a {
      color: $text-black;
    }
  }

  .header--sticky & {
    display: none;
  }
}

.header__bt-logo {
  svg {
    display: block;
    height: 1.25rem;
    width: 11.75rem;

    @media (max-width: $header-breakpoint) {
      height: 1rem;
      width: 9.5rem;
    }
  }
}

.header__top-container {
  padding: 0.75rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $header-breakpoint) {
    padding: 0.5rem 0;
  }
}

.header__top-aside--desktop {
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 0.75rem;
    padding-left: 0.75rem;
    border-left: 1px solid rgba($white, 0.15);
  }

  @media (max-width: $header-breakpoint) {
    display: none;
  }
}

.header__utility-nav {
  display: flex;
  align-items: center;
  margin-bottom: 0;

  li + li {
    margin-left: 0.75rem;
    padding-left: 0.75rem;
    border-left: 1px solid rgba($white, 0.25);
  }
}

.header__top-item {
  opacity: 0.75;
}

.header__top-button {
  opacity: 0.75;
  transition: opacity 0.25s;

  &:hover {
    opacity: 1;
  }
}

// Header main

.header__main {
  background: $header-bg-colour;
  color: $header-text-color;
  box-shadow: $header-main-shadow;

  a {
    color: $header-text-color;
    text-decoration: none;
  }
}

.header__main-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;

  @media (max-width: $header-breakpoint) {
    padding-right: 0;
  }
}

.header__logo {
  display: flex;
  align-items: center;

  img {
    display: block;
    height: 2.5rem;
    transition: height 0.15s ease;

    .header--shrink & {
      height: 2rem;
    }

    @media (max-width: $header-breakpoint) {
      height: 1.75rem;
    }
  }
}

.header__logo--tall {
  img {
    height: 3.5rem;

    @media (max-width: $header-breakpoint) {
      height: 2.25rem;
    }
  }
}

// Desktop & mobile variations

.header__main--desktop {
  @media (max-width: $header-breakpoint) {
    display: none;
  }
}

.header__main--mobile {
  display: none;
  align-items: stretch;

  .header-actions {
    margin-right: 0.5rem;
    display: flex;

    button,
    a {
      padding: 1.25rem 0.5rem;
      display: flex;
      align-items: center;
    }
  }

  .header__menu-button {
    padding: 1rem 0.75rem;
    color: $header-menu-button-text-color;
    background: $header-menu-button-bg-colour;
    display: flex;
    align-items: center;
  }

  i {
    width: 1rem;
    text-align: center;
  }

  @media (max-width: $header-breakpoint) {
    display: flex;
  }
}

// Desktop search

.header__search--desktop {
  display: flex;
  background-color: rgba($white, 0.15);
  align-items: stretch;
  border-radius: $border-radius;
  transition: background-color 0.25s;

  input {
    padding: 0.25rem 0.5rem;
    flex-grow: 1;
    background: transparent;
    border: none;
    min-width: 0;
    color: $text-white;
    width: 10rem;

    &::placeholder {
      color: rgba($white, 0.5);
    }
  }

  button {
    padding: 0 0.5rem;
    transition: color 0.25s;
    flex-shrink: 0;
  }

  &:focus-within {
    background-color: rgba($white, 1);

    button {
      color: $text-black;
    }

    input {
      color: $text-black;

      &::placeholder {
        color: rgba($black, 0.5);
      }
    }
  }
}

// Mobile search

.header__search--mobile {
  display: flex;
  align-items: stretch;
  position: absolute;
  top: 100%;
  width: 100%;
  background: $white;

  input {
    flex-grow: 1;
    border: none;
    padding: 0.25rem $container-padding;
  }

  button {
    flex-shrink: 0;
    padding: 0.75rem;
  }
}

// Mobile menu

.header__mobile-menu {
  display: block;
  color: $text-black;
  position: absolute;
  top: 100%;
  width: 100%;
  overflow: auto;
  border-top: $header-mobile-menu-border-top;

  @media (min-width: $header-breakpoint) {
    display: none;
  }
}

.header__main--sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
