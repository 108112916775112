.booking-confidence__ul {
  @include list-unstyled;
  @include text-small;

  li {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 0.25rem;

    i {
      position: absolute;
      top: 0.25rem;
      left: 0;
      color: $success;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.booking-confidence--no-booking {
  color: $muted;
}
