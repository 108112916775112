.booking-cart-item {
  display: flex;
  background-color: $white;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    height: auto;
  }
}

.booking-cart-item__image-wrapper {
  max-width: 30%;
}

.booking-cart-item__top {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.booking-cart-item__content-wrapper {
  padding: 1rem;

  @include media-breakpoint-up(sm) {
    padding-left: 0.5rem;
  }
}

.booking-cart-item__actions {
  @include text-xxx-small;
}

.booking-cart-item__action {
  cursor: pointer;
  margin-right: 0.75rem;
  text-decoration: underline;

  &:last-of-type {
    margin-right: 0;
  }
}

.booking-cart-item__action--disabled {
  cursor: no-drop;
}

.booking-cart-item__name {
  @include text-x-large;

  font-weight: 700;
}

.booking-cart-item__date {
  @include text-small;

  i {
    margin-right: 0.25rem;
  }
}

.booking-cart-item__pax,
.booking-cart-item__add-on {
  @include text-small;
}

.booking-cart-item__price {
  text-align: left;

  @include media-breakpoint-up(sm) {
    text-align: right;
  }
}

.booking-cart-item__add-on-title {
  font-weight: 700;
}
