.button--input-group {
  @include button-variant($gray-200, $gray-400);
}

// Button arrow box

.btn-arrow-box-right,
.btn-arrow-box-left {
  position: relative;
  display: block;
  width: 3.75rem;
  height: 3.75rem;
  border: 4px solid $primary;
  background-color: white;

  &::after {
    position: absolute;
    top: 1.1rem;
    left: 0.9rem;
    content: "";
    width: 1rem;
    height: 1rem;
    border: 4px solid $primary;
    transform: rotate(45deg);
  }

  &:hover {
    background-color: $primary;
    border: 4px solid white;

    &::after {
      border: 4px solid white;
    }
  }
}

.btn-arrow-box-right {
  &::after {
    border-bottom: none;
    border-left: none;
  }

  &:hover {
    &::after {
      border-bottom: none;
      border-left: none;
    }
  }
}

.btn-arrow-box-left {
  &::after {
    left: 1.2rem;
    border-top: none;
    border-right: none;
  }

  &:hover {
    &::after {
      border-top: none;
      border-right: none;
    }
  }
}
