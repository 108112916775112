.bt-footer {
  padding: 1.3rem 0;
  color: #fff;
  background-color: #414042;

  @include media-breakpoint-up(md) {
    padding: 0.875rem 0;
  }

  a {
    color: #fff;
  }

  .bt-footer__our-brands-button-no-bs {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    text-transform: capitalize;
    border: 1px solid #fff;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #fff;
    text-decoration: none;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    &:hover {
      color: #414042;
      background-color: #fff;
      border-color: #fff;
    }
  }
}

.bt-footer__utility-link {
  position: relative;
  display: inline-block;
  margin-right: 0.5em;
  line-height: 1.1em;
  text-decoration: underline;

  &:hover {
    color: #ccc;
    text-decoration: none;
  }
}

.bt-footer__copyright-link {
  &:hover {
    color: #ccc;
  }

  text-decoration: none;
}

.bt-footer__divider {
  height: 1px;
  width: 100%;
  background-color: #e5e5e5;
  margin: 1.5rem 0;
}

.bt-footer__copyright-info {
  font-size: 0.75rem;
}

.bt-footer__our-brands-button {
  font-size: 0.875rem;
}

.bt-footer__logo-wrapper {
  img {
    max-width: 360px;
    width: 100%;
  }
}
