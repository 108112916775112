.checkout__back {
  display: block;
  margin-bottom: 1.25rem;
}

.checkout__back,
.checkout__link {
  color: inherit;
}

.datepicker__item {
  line-height: 1;
}

.datepicker__date {
  @include font($bold-font);

  font-size: 0.8rem;
}

.datepicker__price {
  opacity: 0.75;
  font-size: 0.7rem;
  margin-top: 0.25rem;
}

.datepicker__price-discount {
  color: $success;
}
