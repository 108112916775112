.search-widget-of-sea-breeze {
  background-color: $white;

  @include media-breakpoint-up(lg) {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translatex(-50%);
    box-shadow: 15px -12px 16px 0 #0000001a;
  }
}

.search-widget--title {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
  flex-basis: 12rem;
}

.search-widget--submit-btn {
  margin-left: 1.5rem;
  margin-top: 0.5rem;
  flex-basis: 10.5rem;
  align-self: stretch;

  @include media-breakpoint-up(lg) {
    margin: 0;
  }
}

.search-widget--submit-btn-text {
  font-size: 1.35rem;
  font-weight: 700;
  height: 100%;
}
