// BT Footer

.bt__wrapper {
  display: flex;
  flex-direction: column;
}

.bt-footer__logo {
  width: 280px;
  margin: 0 auto;

  @include media-breakpoint-up(sm) {
    width: 500px;
  }
}

.bt-footer__logos-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 2rem 0;
  padding: 2rem 0;
  border-top: solid 1px $white;
  border-bottom: solid 1px $white;

  .bt-footer-logo-link {
    display: flex;
    flex-basis: 33.33%;
    margin: 0.75rem 0;

    @include media-breakpoint-up(sm) {
      flex-basis: 25%;
    }

    @include media-breakpoint-up(md) {
      flex-basis: 16.66%;
    }
  }

  img {
    width: 80%;
    margin: 0 auto;
    max-height: 85px;
    padding: 3px 0;
  }
}
