.gift-card {
  .gift-card__logo {
    width: 5.5rem;
  }

  .accordion-button {
    padding-top: 0;
  }
}

.gift-card--always-on {
  .gift-card__logo {
    display: inline-block;
  }

  .accordion-header {
    pointer-events: none;
  }

  .accordion-button {
    @include heading-four;

    &::after {
      display: none;
    }
  }

  .accordion-body {
    margin: 1rem 0;
  }
}
