_:-ms-fullscreen,
:root .header__logo img {
  width: 110px;
  height: 50px;
}

_:-ms-fullscreen,
:root .dialog.fixed {
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
