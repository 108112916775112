// ***********************
// Colour
// ***********************

$primary: #0070b9;
$secondary: #1f3d7c;
$tertiary: #00aeef;
$accent: #faa21b;
$grey: #919191;
$divider: #e5e5e5;
$white: #fff;
$light: #f5f5f5;
$success: #219653;
$danger: #eb5757;
$warning: #ffc107;
$info: $tertiary;
$black: #282828;
$muted: #999 !default;
$body-color: $black;

$bt-charcoal: #414042;

$custom-colors: (
  "tertiary": $tertiary,
  "accent": $accent,
  "grey-border": #d0d0d1,
  "grey": $grey,
  "divider": $divider,
  "dark": $black,
);

$text-white: $white;
$text-black: $black;
$text-grey: $grey;

// ***********************
// Typography
// ***********************

$font-family-base: "Roboto", sans-serif;
$font-weight-normal: 400;
$line-height-base: 1.5;
$font-weight-bold: 500;

// ***********************
// Breakpoints
// ***********************

$custom-breakpoints: (
  "xxxl": 1800px,
);

$header-breakpoint: 960px;
$container-padding: 1rem;

// ***********************
// Spacing system
// ***********************

$grid-gutter-width: 2rem;
$spacer: 1rem;
$enable-negative-margins: true;

// For some reason these spacing units no not generate negative margin BS utilities

$custom-spacers: (
  5: (
    $spacer * 2,
  ),
  6: (
    $spacer * 2.5,
  ),
  7: (
    $spacer * 3,
  ),
  8: (
    $spacer * 3.5,
  ),
  9: (
    $spacer * 4,
  ),
  10: (
    $spacer * 5,
  ),
);

// ***********************
// Gutters
// ***********************

$custom-gutters: (
  5: (
    $spacer * 2,
  ),
  8: (
    $spacer * 3.5,
  ),
);

// ***********************
// Breadcrumb
// ***********************

$breadcrumb-bg: $light;
$breadcrumb-divider-color: $black;

// ***********************
// Borders
// ***********************

$border-radius: 0.25rem;
$border-radius-lg: 0.3rem;
$border-color: $divider;

// ***********************
// Alerts
// ***********************

$alert-border-scale: 0;
$alert-bg-scale: -90%;
$alert-padding-y: $spacer * 0.75;

// ***********************
// Accordions
// ***********************

$accordion-padding-y: 1.25rem;
$accordion-padding-x: 0;
$accordion-border-color: $divider;
$accordion-button-active-color: $text-black;
$accordion-button-active-bg: $light;

$accordion-icon-color: $grey;
$accordion-icon-active-color: $text-black;
$accordion-icon-width: 0.875rem;
$accordion-button-icon: url("data:image/svg+xml,<svg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6.48176 8.83116L0.547891 2.89727C0.261706 2.61108 0.261706 2.1471 0.547891 1.86095L1.23998 1.16886C1.52568 0.883159 1.98871 0.882609 2.27508 1.16763L6.99993 5.87035L11.7248 1.16763C12.0111 0.882609 12.4742 0.883159 12.7599 1.16886L13.4519 1.86095C13.7381 2.14713 13.7381 2.61111 13.4519 2.89727L7.51811 8.83116C7.23192 9.11732 6.76794 9.11732 6.48176 8.83116Z' fill='#{$accordion-icon-color}' /></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6.48176 8.83116L0.547891 2.89727C0.261706 2.61108 0.261706 2.1471 0.547891 1.86095L1.23998 1.16886C1.52568 0.883159 1.98871 0.882609 2.27508 1.16763L6.99993 5.87035L11.7248 1.16763C12.0111 0.882609 12.4742 0.883159 12.7599 1.16886L13.4519 1.86095C13.7381 2.14713 13.7381 2.61111 13.4519 2.89727L7.51811 8.83116C7.23192 9.11732 6.76794 9.11732 6.48176 8.83116Z' fill='#{$accordion-icon-active-color}' /></svg>");

// ***********************
// Forms
// ***********************

$focus-ring-color: transparent;
$input-focus-border-color: $primary;
$input-placeholder-color: rgba($text-grey, 0.7);
$form-label-margin-bottom: 0.25rem;
$form-label-font-weight: 500;

$form-check-input-width: 1.25em;
$form-check-input-radio-checked-color: $primary;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><rect y='0.5' width='18' height='18' rx='10' fill='white'/><rect x='3' y='3' width='12' height='12' rx='7' fill='#{$form-check-input-radio-checked-color}'/></svg>");

// ***********************
// Tabs
// ***********************

$tab-border-width: 3px;

// ***********************
// Box shadow
// ***********************

$default-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
$large-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);

// ***********************
// Aspect ratios
// ***********************

$aspect-ratios: (
  "1x1": 100%,
  "3x2": calc(2 / 3 * 100%),
  "3x4": calc(4 / 3 * 100%),
  "4x3": calc(3 / 4 * 100%),
  "9x16": calc(16 / 9 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
);

// ***********************
// Timings
// ***********************

$default-timing-slow: 0.5s;
$default-timing: 0.25s;
$default-timing-fast: 0.125s;

// ***********************
// Dropdowns
// ***********************

$dropdown-header-color: $grey;
$dropdown-link-hover-bg: transparent;
$dropdown-box-shadow: $default-shadow;
$dropdown-font-size: 0.875rem;
$dropdown-link-color: $body-color;
$dropdown-border-color: $divider;

// ***********************
// OffCanvas
// ***********************

$offcanvas-horizontal-width: 600px;
$offcanvas-padding-y: 1.5rem;
$offcanvas-padding-x: 1.5rem;

// ***********************
// Modals
// ***********************

$modal-content-color: $text-black;

// ***********************
// Tooltips
// ***********************

$tooltip-bg: $black;

// ***********************
// Banner
// ***********************

$banner-mobile-breakpoint: 576px;
