.mobiscroll-classic {
  $dark-grey: #484848;
  $grey: #757575;
  $light-grey: #c4c4c4;
  $lighter-grey: #e4e7e7;

  .mbsc-calendar-controls {
    display: flex;
    justify-content: space-around;
    padding: 0.5rem 0;
  }

  .mbsc-icon-button {
    color: $grey !important;
    border: 1px solid $lighter-grey;
    border-radius: 3px;

    &.mbsc-hover {
      border-color: $light-grey;
      opacity: initial;
    }
  }

  .mbsc-calendar-title {
    color: $dark-grey;
    font-weight: 700;
    font-size: 1.25rem;
  }

  .mbsc-calendar-week-day {
    font-size: 1rem;
    color: $grey;
    height: 2rem;
  }

  .mbsc-calendar-day {
    height: 2.5rem;
  }

  .mbsc-calendar-label {
    color: transparent;
  }

  .mbsc-hover.mbsc-calendar-cell:not(.mbsc-calendar-day-empty),
  .mbsc-selected {
    background-color: $primary !important;
    color: $white;

    .mbsc-calendar-cell-text {
      background-color: transparent;
    }

    .mbsc-calendar-label-text {
      color: $white !important;
      opacity: 0.75;
    }
  }

  .mbsc-ios.mbsc-calendar-day-text {
    width: auto !important;
    height: auto !important;
    line-height: 1em !important;
    font-size: 1rem !important;
    font-weight: 600;
    border: 0;
    border-radius: 0;
  }

  .mbsc-calendar-text {
    height: 1em;
    line-height: 1em;

    &.mbsc-ltr {
      text-align: center;
    }
  }

  .mbsc-calendar-label-text {
    color: $dark-grey;
    opacity: 0.75;
    font-size: 0.75rem;
    font-weight: 400;
    text-overflow: unset;
  }
}
