// Applying styles for vendor provided markup - https://tripleseat.com/

.tripleseat-form__wrapper {
  #tripleseat_embed_form { /* stylelint-disable-line selector-id-pattern */
    .row_submit {
      .button {
        @extend .btn;
        @extend .btn-lg;
        @extend .btn-primary;

        display: block;
        width: 100%;
        border: none;
        margin-bottom: 1rem;
      }
    }

    @include media-breakpoint-down(md) {
      > table {
        td {
          display: block;
        }
      }
    }

    h2 {
      @include text-x-large;
    }

    #lead_phone_number { /* stylelint-disable-line selector-id-pattern */
      width: 100%;
    }

    label {
      margin-top: 0.5rem;
    }

    input,
    textarea {
      border: 1px solid $divider;
    }

    input.text,
    input.timeselect_input,
    textarea {
      @extend .form-control;
    }

    textarea {
      resize: vertical;
    }

    select {
      @extend .form-select;
    }

    input.timeselect_input,
    input.hasDatepicker {
      padding-left: 1.5rem;
      min-width: 16.5rem;
      background-position: 5px center;
    }

    input.checkbox {
      @extend .form-check-input;
    }

    .timeselect {
      width: auto;
    }

    .row_contact_header,
    .row_details_header {
      color: $primary;
    }

    .row_details_header {
      h2 {
        margin-top: 1.5rem;
      }
    }

    #lead_phone_number_extension { /* stylelint-disable-line selector-id-pattern */
      width: 7rem;
    }

    .fa-exclamation-triangle {
      max-width: 1.25rem;
      margin-bottom: 0.5rem;
      float: right;
    }

    .help-block {
      margin-bottom: 0.25rem;
    }

    .danger {
      color: $danger;
    }

    .row_gdpr_consent {
      .checkbox {
        margin-top: 1.25rem;
      }
    }
  }

  .errorExplanation {
    padding: 1rem;
    background-color: rgba($danger, 0.1);

    ul {
      @include text-small;
    }
  }
}
