.list--icons {
  @include list-unstyled;

  li {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 0.5rem;

    i {
      position: absolute;
      top: 4px;
      left: 0;
      color: $primary;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
