@use "sass:math";
@use "sass:map";

// stylelint-disable-next-line no-invalid-position-at-import-rule
@import "~tiny-slider/src/tiny-slider";

$nav-height: 0.33rem;
$nav-width: 2rem;

// TNS

.tns-outer {
  position: relative;
}

.tns-slider {
  margin-bottom: 0;
}

.tns-controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: map.get($zindex, carousel-controls);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;

  button {
    pointer-events: auto;
    opacity: 0.5;
    transition: opacity 0.25s;
    display: block;

    &:hover {
      opacity: 1;
    }

    &:disabled {
      opacity: 0.2;

      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

.tns-nav {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    opacity: 0.5;
    transition: opacity 0.25s;
    z-index: map.get($zindex, carousel-controls);
    display: block;
    width: $nav-width;
    height: $nav-height;
    margin: 0 0.25rem;
    border-radius: math.div($nav-height, 2);

    &:hover,
    &.tns-nav-active {
      opacity: 1;
    }
  }
}

// Carousel wrapper

.carousel__wrapper {
  position: relative;
}

// Carousel banner

.carousel {
  overflow: hidden;

  // Ultrawide
  @include ratio-max-height(700, 2400);

  // Desktop
  @media screen and (max-width: 1900px) {
    @include ratio-max-height(700, 1900);
  }

  // Tablet
  @media screen and (max-width: 1100px) {
    @include ratio-max-height(700, 1100);
  }

  // Mobile
  @media screen and (max-width: 600px) {
    @include ratio-max-height(700, 600);
  }

  .tns-controls {
    padding: 0 1.5rem;

    button {
      color: $white;
    }
  }

  .tns-nav {
    position: absolute;
    bottom: 1.5rem;
    left: 0;
    right: 0;

    button {
      background: $white;
    }
  }
}

//
// *****Carousel cards*****
//

.carousel--cards {
  .tns-controls {
    left: -4rem;
    width: calc(100% + 8rem);

    button {
      color: $primary;
    }
  }

  .tns-nav {
    margin-top: 2rem;

    button {
      background: $primary;
    }
  }

  .tns-inner {
    & > * {
      display: flex;
    }
  }
}

.carousel__with-search-widget {
  @include media-breakpoint-up(lg) {
    .tns-nav {
      bottom: 9rem;
    }
  }
}
