.breadcrumbs {
  background: $light;
}

.breadcrumbs__ul {
  @include list-unstyled;
  @include text-xxx-small;

  color: $text-grey;
  display: flex;
}

.breadcrumbs__li {
  display: block;
  margin-left: 0.25rem;

  &:first-of-type {
    margin-left: 0;
  }

  &:not(:first-child)::before {
    @include fa-icon("\f054", $text-grey); // fa chevron-right

    margin-right: 5px;

    @include text-xxx-small;
  }

  a {
    color: $text-grey;
    text-decoration: underline;
  }

  @include media-breakpoint-down(lg) {
    max-width: 8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 2.5rem;
  }
}
