.image__wrapper {
  figcaption {
    max-width: 90%;
    position: relative;

    @include media-breakpoint-up(md) {
      max-width: 70%;
    }

    &:last-of-type {
      padding-bottom: 1.25rem;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 4rem;
        height: 0.25rem;
        background-color: $primary;
      }
    }
  }
}

.image__caption {
  @include text-small;

  color: $text-grey;
}

.image__attribution {
  @include text-x-small;

  color: $text-grey;
}
