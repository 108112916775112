@use "sass:map";

$nav-height: 0.25rem;
$nav-bottom-offset: 0.75rem;
$nav-padding: 0.5rem;

.carousel-container {
  img {
    height: 18rem;
    width: 18rem;
    object-fit: cover;
  }

  .gallery-img {
    width: 17rem;
    height: 24rem;

    @include media-breakpoint-up(md) {
      width: 24rem;
      height: 34rem;
    }
  }

  .gallery-img__stack {
    width: 17rem;
    height: 11.5rem;

    @include media-breakpoint-up(md) {
      height: 16.5rem;
      width: 24rem;
    }
  }

  .tns-nav,
  .tns-liveregion {
    display: none;
  }

  .tns-controls {
    height: 0;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    button {
      opacity: 1;
    }
  }
}

.instagram-btn__right,
.instagram-btn__left,
.gallery-btn__right,
.gallery-btn__left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.instagram-btn__right {
  display: block;
  right: 5rem;

  @include media-breakpoint-up(md) {
    right: 10rem;
  }
}

.instagram-btn__left {
  display: none;
  left: 5rem;

  @include media-breakpoint-up(md) {
    left: 10rem;
  }
}

.gallery-btn__right {
  display: block;
  right: 5rem;
}

.gallery-btn__left {
  display: none;
  left: 5rem;
}

.card {
  .tns-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: $nav-bottom-offset;
    left: 0;
    right: 0;
    margin-bottom: 0;
    z-index: map.get($zindex, carousel-controls);

    button {
      opacity: 0.75;
      transition: opacity 0.25s;
      z-index: map.get($zindex, carousel-controls);
      padding: $nav-padding 0.25rem;

      &::before {
        content: "";
        display: block;
        width: 1rem;
        height: $nav-height;
        background: $white;
      }

      &:hover,
      &.tns-nav-active {
        opacity: 1;
      }
    }
  }
}

// We overwrite some layout here to fix swiping issue on mobile safari.
#instafeed-iw {
  height: 18rem;

  .tns-horizontal.tns-subpixel > .tns-item {
    vertical-align: inherit;
  }
}
