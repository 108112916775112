.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-up(sm) {
    justify-content: center;
  }
}

.pagination__navigation {
  display: flex;
  margin: 0 0.25rem;

  & > * + * {
    margin-left: 0.25rem;
  }
}

.pagination__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
}

.pagination__ellipses {
  width: auto;
}

.pagination__item--active {
  background: $primary;
  color: $text-white;
}

.pagination__action {
  transition: background-color 0.25s;
  text-decoration: none;
  color: $text-black;

  &:hover {
    background-color: $primary;
    text-decoration: none;
    color: $text-white;
  }
}

.pagination__item--inactive {
  opacity: 0.25;
}

.page-item {
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.page-link {
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  margin: 0 0.25rem;
  align-items: center;
  justify-content: center;
  border-radius: 1.25rem !important;
  color: $black;
}
