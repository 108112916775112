.content {
  // Spacing

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 1rem;
  }

  p,
  ul,
  ol,
  .table-responsive {
    margin: 1rem 0;
  }

  img {
    margin: 1.5rem 0;
    max-width: 100%;
    height: auto;
    display: inline; // Added so that content editor can have images sitting next to each other
  }

  // Remove spacing from first and last elements

  & > *:last-child {
    margin-bottom: 0;
  }

  & > *:first-child {
    margin-top: 0;
  }

  table {
    @extend .table, .table-striped;

    margin-bottom: 0;

    thead th {
      border-bottom: none;
    }

    td.selected-data,
    th.selected-data {
      background-color: $primary;
      color: $white;
    }
  }

  .table-responsive {
    background-image:
      linear-gradient(to right, #fff, #fff),
      linear-gradient(to right, #fff, #fff),
      linear-gradient(to right, rgba(0, 0, 20, 0.15), rgba(255, 255, 255, 0)),
      linear-gradient(to left, rgba(0, 0, 20, 0.15), rgba(255, 255, 255, 0));
    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: 1.5rem 100%, 1.5rem 100%, 1.5rem 100%, 1.5rem 100%;
    background-attachment: local, local, scroll, scroll;

    tr:nth-of-type(odd) {
      background-color: rgba(225, 225, 225, 0.15);
    }
  }
}

// Style anchors as buttons

.content--anchor-buttons {
  a {
    @extend .btn, .btn-primary;

    text-decoration: none;
    color: $text-white;
    text-shadow: none;
  }
}
