.review-cart__promo-code {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include media-breakpoint-up(sm) {
    max-width: 45%;
    margin-left: auto;
  }
}

.review-cart__total {
  @include text-xx-large;

  font-weight: 700;
}
