@use "sass:map";

.quick-view-search {
  transform: translateY(-70%);

  .quick-view-search__label {
    line-height: 2.5rem;
  }

  .input-with-icon {
    width: 55%;
    max-width: 250px;

    .input-with-icon__icon {
      &.primary {
        color: $primary;
      }
    }
  }
}

.availability-quick-view {
  position: relative;
  z-index: map.get($zindex, availability-quick-view);

  @include text-xxxx-small;

  @include media-breakpoint-down(md) {
    @include text-xxx-small;
  }

  > i {
    display: block;
    color: $grey;

    &:hover {
      color: $primary;
    }
  }
}

.availability-quick-view__seat-box {
  width: 1.25rem;
  line-height: 1.25rem;
  border-radius: 3px;

  &:hover {
    cursor: pointer;
  }

  &.bg-danger {
    pointer-events: none;
  }
}

.availability-quick-view__skeleton-frame {
  width: 1.25rem;
  height: 1.25rem;

  span {
    display: block;
    height: 100%;
  }
}

.availability-quick-view__pricing-mode {
  .availability-quick-view__date {
    margin-bottom: 0.1rem;
  }

  .availability-quick-view__skeleton-frame {
    width: 2rem;
    height: 1.25rem;
  }
}

.card--featured {
  .availability-quick-view {
    @include text-xxx-small;

    @include media-breakpoint-only(md) {
      @include text-xxxx-small;
    }
  }

  .availability-quick-view__seat-box {
    width: 1.5rem;
    line-height: 1.5rem;
    border-radius: 4px;
  }

  .availability-quick-view__skeleton-frame {
    width: 1.5rem;
    height: 1.5rem;
  }
}
