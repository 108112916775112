.booking-steps {
  position: relative;
  padding-bottom: 1rem;
  z-index: 1;
}

.booking-steps__name {
  @include text-xx-small;

  color: $text-black;
}

.booking-steps__dot {
  display: block;
  position: relative;

  i {
    background-color: #fff;
    border-radius: 50%;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    background: $primary;
    height: 1px;
    position: absolute;
    bottom: 50%;
    z-index: -1;
  }

  &::before {
    width: 50%;
    left: 0;
  }

  &::after {
    width: 100%;
    left: 50%;
  }
}

.booking-steps__step {
  display: block;
  pointer-events: none;
  text-decoration: none;
  position: relative;
  text-align: center;
  color: $primary;

  &:first-child {
    .booking-steps__dot {
      &::before {
        display: none;
      }
    }
  }

  &:last-child {
    .booking-steps__dot {
      &::after {
        display: none;
      }
    }
  }
}

.booking-steps__step--clickable {
  pointer-events: auto;
}

.booking-steps--no-booking {
  .booking-steps__step {
    color: $muted;
  }

  .booking-steps__dot {
    &::before,
    &::after {
      color: $muted;
    }
  }
}

.booking-flow__pax-title {
  padding: 1.25rem 1.5rem;
  background-color: $light;
  border-top: solid 1px $divider;
}

.booking-flow__pax-selections {
  padding: 1.25rem 1.5rem;
}
