@use "sass:list";

@mixin font($list) {
  font-family: list.nth($list, 1);
  font-weight: list.nth($list, 2); /* stylelint-disable-line font-weight-notation */
}

//
// Theming options
//

$body-font: ("Open Sans", sans-serif) normal !default;
$bold-font: ("Open Sans", sans-serif) bold !default;
$heading-font: ("Open Sans", sans-serif) bold !default;

$typography-heading-transform: none !default;
$typography-heading-line-height: 1.2 !default;
$typography-heading-letter-spacing: 0 !default;

//
// Root
//

html {
  font-size: 100%;
}

@mixin body-text {
  @include font($body-font);

  font-size: 1rem;
  line-height: 1.5;
}

body,
.body-text {
  @include body-text();
}

//
// Small text
//

@mixin text-small {
  font-size: 0.9375rem;
}

@mixin text-x-small {
  font-size: 0.875rem;
}

@mixin text-xx-small {
  font-size: 0.8125rem;
}

@mixin text-xxx-small {
  font-size: 0.75rem;
}

@mixin text-xxxx-small {
  font-size: 0.625rem;
}

.text-small {
  @include text-small;
}

.text-x-small {
  @include text-x-small;
}

.text-xx-small {
  @include text-xx-small;
}

.text-xxx-small {
  @include text-xxx-small;
}

.text-xxxx-small {
  @include text-xxxx-small;
}

//
// Large text
//

@mixin text-large {
  font-size: 1.125rem;
}

@mixin text-x-large {
  font-size: 1.25rem;
}

@mixin text-xx-large {
  font-size: 1.5rem;
}

@mixin text-xxx-large {
  font-size: 1.75rem;
}

@mixin text-xxxx-large {
  font-size: 2rem;
}

.text-large {
  @include text-large;
}

.text-x-large {
  @include text-x-large;
}

.text-xx-large {
  @include text-xx-large;
}

.text-xxx-large {
  @include text-xxx-large;
}

.text-xxxx-large {
  @include text-xxxx-large;
}

//
// Headings
//

@mixin heading-shared {
  @include font($heading-font);

  text-transform: $typography-heading-transform;
  line-height: $typography-heading-line-height;
  transition: font-size 0.25s;
  letter-spacing: $typography-heading-letter-spacing;
}

@mixin heading-one {
  @include heading-shared;

  font-size: 2rem;

  @include media-breakpoint-up(sm) {
    font-size: 2.75rem;
  }
}

@mixin heading-two {
  @include heading-shared;

  font-size: 1.5rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.75rem;
  }
}

@mixin heading-three {
  @include heading-shared;

  font-size: 1.4rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.6rem;
  }
}

@mixin heading-four {
  @include heading-shared;

  font-size: 1.3rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.45rem;
  }
}

@mixin heading-five {
  @include heading-shared;

  font-size: 1.2rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.3rem;
  }
}

@mixin heading-six {
  @include heading-shared;

  font-size: 1.1rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.15rem;
  }
}

h1,
.h1 {
  @include heading-one;
}

h2,
.h2 {
  @include heading-two;
}

h3,
.h3 {
  @include heading-three;
}

h4,
.h4 {
  @include heading-four;
}

h5,
.h5 {
  @include heading-five;
}

h6,
.h6 {
  @include heading-six;
}

//
// Horizontal rules
//

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid $divider;
}

//
// Emphasis
//

small,
.small {
  @include text-small;
  @include font($body-font);
}

mark,
.mark {
  padding: 0.25rem;
  background-color: $primary;
}

.text-underline {
  text-decoration: underline;
}

//
// Lists
//

.list-unstyled {
  @include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled;
}

.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}

//
// Strong and b
//

.strong,
strong,
b {
  font-weight: 700;
}

//
// Links
//

a {
  color: $primary;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.link-unstyled {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

//
// Fonts
//

.font-body {
  @include font($body-font);
}

.font-heading {
  @include font($heading-font);
}

.font-bold {
  @include font($bold-font);
}

//
// Utilities
//

.text-line-through {
  text-decoration: line-through;
}

@for $i from 1 through 6 {
  .line-clamp-#{$i} {
    @include line-clamp($i);
  }
}
