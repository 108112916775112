.banner-notification__wrapper {
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 0.75rem 0;

  a {
    color: $text-black;
  }

  button {
    align-items: center;
    display: block;
    padding-left: 0.75rem;
  }
}

.banner-notification--normal {
  background: $light;
}

.banner-notification--critical {
  background: $warning;
}
