.form-control--sans-spinner[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.form-select {
  -webkit-appearance: none;
}

.form-control:disabled {
  opacity: $btn-disabled-opacity;
}

label[for] {
  cursor: pointer;
}

button[disabled] {
  cursor: no-drop;
}

.form-check-input {
  min-width: 1rem;
}

.form-control--braintree-same-height {
  height: 2.5rem;
}
