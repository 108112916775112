@use "sass:math";

$x-narrow: $container-width * math.div(6, 12);
$narrow: $container-width * math.div(8, 12);
$medium: $container-width * math.div(10, 12);

@mixin container($max-width) {
  max-width: $max-width;
  padding-left: $container-padding-mobile;
  padding-right: $container-padding-mobile;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-up(md) {
    padding-left: $container-padding-desktop;
    padding-right: $container-padding-desktop;
  }
}

// Centered containers

.container {
  @include container($container-width);
}

.container--x-narrow {
  @include container($x-narrow);
}

.container--narrow {
  @include container($narrow);
}

.container--medium {
  @include container($medium);
}

// Max widths

.mw-default {
  max-width: $container-width;
}

.mw--x-narrow {
  max-width: $x-narrow;
}

.mw--narrow {
  max-width: $narrow;
}

.mw--medium {
  max-width: $medium;
}

// Flush container

.container--flush {
  margin-left: -1.5rem;
  margin-right: -1.5rem;

  @include media-breakpoint-up(md) {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  @include media-breakpoint-up(lg) {
    margin-left: 0;
    margin-right: 0;
  }
}
