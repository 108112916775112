.pax__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pax__stepper {
  max-width: 8.5rem;
  min-width: 8.25rem;

  .input-group-prepend .button--input-group {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group-append .button--input-group {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
