.grid-banner__outer {
  background: linear-gradient(180deg, rgba($light, 1) 50%, rgba($light, 0) 50%);
}

.grid-banner {
  display: none;

  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.grid-banner__carousel {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.grid-banner__featured-image {
  width: 66.66666%;
}

.grid-banner__secondary-images {
  width: 33.33333%;
}

.grid-banner__image-wrapper {
  width: 100%;
}

.grid-banner__button {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    transition: transform 0.25s;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

.grid-banner__count {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.33);
  color: $text-white;
  display: flex;
  align-items: center;
  justify-content: center;

  @include heading-four();
}

.grid-banner__stretch {
  display: flex;
  align-items: stretch;
}
