.booking-history__titles {
  margin-bottom: 0.5rem;

  .booking-history__title {
    font-weight: 600;
  }

  @include media-breakpoint-down(md) {
    & + .booking-history__item {
      padding-top: 0;
    }
  }
}

.booking-history__item {
  position: relative;
  flex-wrap: wrap;
  padding: 0.75rem 0 1rem;
  margin-bottom: 0.5rem;

  &::before {
    content: "";
    position: absolute;
    right: 1rem;
    left: 1rem;
    bottom: 0;
    border-bottom: solid 1px $divider;
  }

  @include media-breakpoint-up(md) {
    padding: 0.5rem 0;

    &::before {
      display: none;
    }
  }

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;

    &::before {
      display: none;
    }
  }
}

@mixin label($label) {
  &::before {
    content: $label;
    display: block;

    @include text-small;

    color: $text-grey;
  }

  @include media-breakpoint-up(md) {
    &::before {
      display: none;
    }
  }
}

.booking-history__booking-ref {
  @include media-breakpoint-down(md) {
    @include text-large;

    margin-bottom: 0.5rem;
  }

  @include label("Booking reference");
}

.booking-history__name {
  @include label("Contact name");
}

.booking-history__first-travel-date {
  @include label("First travel date");
}

.booking-history__last-travel-date {
  @include label("Last travel date");
}
