$indent: 1.25rem;

.sub-nav {
  border: 1px solid $divider;
  border-radius: $border-radius;
  overflow: hidden;
}

.sub-nav__heading {
  a {
    text-decoration: none;
    color: $text-black;

    &:hover {
      text-decoration: underline;
    }
  }
}

.sub-nav__item {
  & + .sub-nav__item {
    border-top: 1px solid $divider;
  }
}

.sub-nav__link-wrapper {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;

  &.active {
    &::before {
      content: "";
      width: 0.25rem;
      background: $primary;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }
}

.sub-nav__link {
  padding: 1rem 0 1rem $indent;
  flex-grow: 1;
  text-decoration: none;
  color: $text-black;

  &:only-child {
    padding-right: $indent;
  }

  &:hover {
    color: $primary;
    text-decoration: none;
  }
}

.sub-nav__link-button {
  padding: 1rem $indent;
  flex-grow: 0;
  color: $primary;
  border-left: 1px solid $divider;

  i {
    transition: transform 0.25s;
    transform: rotate(180deg);
  }

  &.collapsed {
    i {
      transform: rotate(0deg);
    }
  }
}

.sub-nav__nested-nav {
  border-top: 1px solid $divider;
  background: $light;
}

// First level
.sub-nav > li {
  .sub-nav__link {
    padding-left: $indent;
  }

  // Second level
  > ul > li {
    .sub-nav__link {
      padding-left: $indent * 1.5;
    }

    // Third level
    > ul > li {
      .sub-nav__link {
        padding-left: $indent * 2;
      }

      // Fourth level
      > ul > li {
        .sub-nav__link {
          padding-left: $indent * 2.5;
        }

        // Fifth level
        > ul > li {
          .sub-nav__link {
            padding-left: $indent * 3;
          }
        }
      }
    }
  }
}
