.footer {
  color: $text-white;

  a {
    color: $text-white;
  }
}

.footer__nav {
  & > ul {
    column-gap: $grid-gutter-width;

    @include media-breakpoint-up(sm) {
      column-count: 2;
    }

    @include media-breakpoint-up(md) {
      column-count: 3;
    }

    @include media-breakpoint-up(lg) {
      column-count: 4;
    }
  }

  li {
    margin-bottom: 1rem;
  }
}

.footer__contact-link {
  @include media-breakpoint-up(md) {
    border-left: 1px solid $divider; // No resposnive border utils in BS4
  }
}
