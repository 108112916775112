@use "sass:math";
@use "sass:map";

$card-padding: 1.75rem !default;
$card-border-radius: 0.25rem !default;
$card-text-colour: $text-black !default;
$card-border-colour: $divider !default;
$featured-card-padding: $card-padding * 2;

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background-color: $white;
  border: solid 1px $card-border-colour;
  border-radius: $card-border-radius;
  color: $card-text-colour;
  overflow: hidden;

  @include hoverScaleImage();

  .product__price-base {
    @include heading-five;
    @include font($bold-font);
  }
}

.card--capped {
  @include card-capped;
}

.card__body {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: $card-padding;
  justify-content: space-between;
}

.card__title {
  @include heading-four;
}

.card__bottom {
  .card__divider {
    margin: 1rem 0;
    border-top: solid 1px $card-border-colour;
  }
}

.card__badge {
  color: $white;
  position: absolute;
  top: 0;
  left: $card-padding;
  z-index: map.get($zindex, card-badges);
  pointer-events: none;
  padding: 0.5rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  @include media-breakpoint-down(md) {
    left: math.div($card-padding, 2);
  }
}

.card--featured {
  @include media-breakpoint-up(md) {
    flex-direction: row;

    .card__image {
      width: 50%;

      .picture__wrapper {
        padding-bottom: 0;
        height: 100%;
      }
    }

    .card__body {
      width: 50%;
      padding: $featured-card-padding;
    }

    .card__title {
      @include heading-three;
    }
  }
}

.card--compressed {
  .card__title {
    @include heading-five;
  }

  .product__price-base {
    font-size: 1rem;

    @include font($bold-font);
  }
}
