.mbsc-popup-wrapper {
  // Disable past years & months
  .mbsc-calendar-year.mbsc-disabled,
  .mbsc-calendar-month.mbsc-disabled {
    pointer-events: none;
  }

  .mbsc-datepicker-inline {
    border: none;
  }

  .mbsc-popup-body {
    background-color: $white;
  }

  .mbsc-popup-content {
    overflow-x: hidden;
  }
}
