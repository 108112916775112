@use "sass:map";

.product__book-box {
  border: solid 1px $divider;

  @include card-capped;
}

.product__sidebar {
  position: relative;

  .product__book-box {
    @include media-breakpoint-up(md) {
      position: sticky;
      top: 2rem;

      &--nav-offset {
        top: 6rem;
      }
    }
  }
}

.product__subtitle {
  p {
    @include heading-three;
  }
}

.product__sticky-book-btn {
  padding: 0.5rem $container-padding-mobile;
  background: $white;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: map.get($zindex, sticky-book-btn);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease;
  border-top: 1px solid $divider;

  &.active {
    opacity: 1;
    pointer-events: initial;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}
