.Toastify__toast-container {
  width: 18rem;
}

.Toastify__toast {
  &.Toastify__toast {
    &--warning {
      background-color: $warning;
    }

    &--error {
      background-color: $danger;
    }
  }
}

.Toastify__progress-bar--warning {
  background-color: white !important;
}

.Toastify__toast-theme--light {
  color: $white !important;
}

.Toastify__close-button--light {
  color: $white !important;
  opacity: 1 !important;
}
