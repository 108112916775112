.booking-option-card {
  .accordion-header {
    .accordion-button {
      padding: 0;
      font-weight: $font-weight-normal;
      color: $primary;

      &::after {
        display: none !important;
      }
    }
  }

  .accordion-body {
    padding: 0;
  }

  .card-body {
    padding: 1.25rem;
  }
}
