.categories__li {
  .banner & {
    a {
      color: $white;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
