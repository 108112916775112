// Bootstrap overides

.dropdown-menu {
  font-size: inherit;

  a {
    color: $text-black;
  }
}

.dropdown-item {
  padding: 0.25rem 1rem;
  white-space: initial;
}

.dropdown-item--large {
  padding: 0.75rem 1rem;
}
