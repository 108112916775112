@use "sass:math";

@mixin fa-icon($fa-icon-unicode, $colour: inherit, $icon-weight: 400) {
  position: relative;
  display: inline-block;
  font: var(--fa-font-solid);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: "#{$fa-icon-unicode}";

  @if $colour {
    color: $colour;
  }

  @if $icon-weight {
    font-weight: $icon-weight;
  }
}

@mixin aspect-ratio($height, $width) {
  padding-top: math.div($height, $width) * 100%;
}

@mixin hoverScaleImage() {
  picture {
    overflow: hidden;

    img {
      transform: scale(1);
      transition: transform 0.2s ease-out;
    }
  }

  &:hover {
    picture {
      img {
        transform: scale(1.025);
        transition-timing-function: ease-in;
      }
    }
  }
}

@mixin card-capped {
  border-top: 3px $primary solid;
}

.hover-scale-image {
  @include hoverScaleImage();
}

@mixin line-clamp($line) {
  -webkit-line-clamp: $line;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@mixin ratio-max-height($height, $width) {
  max-height: math.div($height, $width) * 100vw;
}

.preload-icon {
  width: 0;
  height: 0;
  visibility: hidden;
  position: absolute !important;
}
