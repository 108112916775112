$gallery-grid-spacing-desktop: 0.75rem;
$gallery-grid-spacing-mobile: 0.25rem;

.gallery-grid {
  display: flex;
  flex-wrap: wrap;
  margin: $gallery-grid-spacing-mobile * -1;

  @include media-breakpoint-up(md) {
    margin: $gallery-grid-spacing-desktop * -1;
  }
}

.gallery-grid__item {
  flex: 0 0 50%;
  padding: $gallery-grid-spacing-mobile;

  @include media-breakpoint-up(md) {
    padding: $gallery-grid-spacing-desktop;
  }
}

.gallery-grid__zoom {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba($black, 0.33);
  color: $white;
  width: 3rem;
  height: 3rem;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.25s;

  .gallery-grid__item:hover &,
  .grid-banner__button:hover & {
    background-color: rgba($black, 0.66);
  }
}
