.accordion__header {
  background-color: $light;
  border-radius: $border-radius;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .bg-light & {
    background-color: $white;
  }

  &::after {
    content: "\f078";
    font: var(--fa-font-solid);
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: $primary;
    transition: all 0.15s ease-out;
  }

  &[aria-expanded="true"] {
    &::after {
      transform: rotate(-180deg);
      transition-timing-function: ease-in;
    }
  }
}

.accordion__content {
  iframe {
    width: 100%;
  }
}
