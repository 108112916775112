.featured-banner {
  color: $text-white;
  position: relative;
}

.featured-banner__image {
  width: 100%;
  position: relative;
  display: block;
  overflow: hidden;

  @include aspect-ratio(1000, 800);

  @include media-breakpoint-up(sm) {
    @include aspect-ratio(600, 800);
  }

  @include media-breakpoint-up(md) {
    @include aspect-ratio(1000, 750);

    .featured-banner--full-width & {
      @include aspect-ratio(500, 1000);
    }
  }

  @include media-breakpoint-up(lg) {
    @include aspect-ratio(500, 1000);

    .featured-banner--full-width & {
      @include aspect-ratio(400, 1000);
    }
  }

  img,
  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.featured-banner__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  @include media-breakpoint-up(lg) {
    padding: 1.5rem 2rem;
    top: 0;
    max-width: 60%;

    .featured-banner--full-width & {
      max-width: 40%;
    }
  }

  .featured-banner__content--right-align & {
    right: 0;
    left: auto;

    .content {
      text-align: right;
    }
  }

  .featured-banner--treatment-box-content & {
    background: rgba($black, 0.5);
  }
}
