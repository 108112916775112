.checkout-form__payment {
  display: flex;
  flex-direction: column;

  > input:checked {
    & + label {
      background-color: #dae0e5 !important;
    }
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.form-group {
  margin-bottom: 1rem;
}

.checkout-form__payment-button {
  display: flex;
  padding: 0.75rem 1rem;
  border: solid 1px $divider !important;
  min-height: 55px;
  flex-basis: 0 !important;
  cursor: pointer;

  span {
    display: inline-block;
    margin-right: 1rem;
  }

  input {
    @extend .btn-check;
  }

  .checkout-payment__logo {
    &--credit-card {
      max-width: 2.5rem;
    }

    &--apple-pay {
      max-width: 4rem;
    }

    &--google-pay {
      min-height: 2.5rem;
      max-width: 4.5rem;
    }

    &--paypal {
      max-width: 85px;
    }
  }
}

.checkout-form__comments {
  min-height: 8rem;
}

.btn-group-toggle {
  &.checkout-form__payment {
    .checkout-form__payment-button {
      // override Boostrap
      flex-basis: 0;
    }
  }
}

.checkout-form-section {
  border: 1px solid #888;
  padding: 1.5rem;
  color: #666;

  .checkout-form-section__title {
    font-weight: 700;
  }
}

.checkout__submit-btn {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.input-block {
  .form-control {
    height: calculateRem(40px);
  }
}

.checkout-form__pax-label {
  flex-basis: 6rem;
  flex-shrink: 0;
}
