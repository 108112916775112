@use "sass:map";

.experience-booking {
  .product-name-head-block {
    background-color: $light;
  }

  .experience-booking__header {
    background-color: $light;
  }

  .booking-flow__pax-title {
    @include heading-four;

    display: block;
    padding: 1.25rem 0 0;
    background-color: transparent;
  }

  .booking-flow__pax-selections {
    padding: 1.5rem 0;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: $blue-300;
    color: white;
    border: $blue-300;

    .eventDay {
      color: white;
      height: 100%;
    }
  }

  .datepicker__date {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inline_datepicker__container {
    border-top: solid 1px $divider;
    padding-top: 1rem;
    padding-bottom: 0.1rem;
  }

  .inline_datepicker__frame {
    .mbsc-selected .mbsc-calendar-cell-text {
      background-color: $primary;
      border-color: $primary;
    }

    .mbsc-calendar-week-day {
      font-size: 1rem;
      color: $grey;
    }

    .mbsc-calendar-label-background {
      background-color: transparent;
    }

    .mbsc-calendar-label-text {
      text-align: center;
      font-weight: 400;

      @include text-xx-small;
    }
  }

  .inline_datepicker__header .mbsc-calendar-title {
    font-size: 1rem;
    color: $text-black;
  }

  .inline_datepicker__nav_button > .mbsc-button-icon {
    color: $grey;
    border: 1px solid $divider;
    border-radius: 0.25rem;
  }

  .pax__stepper,
  .item-selection__stepper {
    max-width: 8.5rem;
    min-width: 8.25rem;

    .form-control {
      padding: 0.5rem;
    }

    .button--input-group {
      padding: 0.75rem 1rem;
      height: 100%;
    }
  }

  .pax__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .booking-flow__pax-wrapper {
    label {
      span {
        font-weight: 400;
      }
    }
  }

  .button--input-group {
    @include button-variant($gray-200, $gray-400);
  }

  input {
    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[readonly] {
      background-color: $white;
    }

    &:disabled {
      background-color: $gray-200;
    }
  }
}

.experience-booking--events {
  .experience-booking__header {
    background-color: unset;
    padding-bottom: 0 !important;
  }

  .booking-flow__pax-selections {
    padding-top: 0 !important;
  }

  .booking-flow__pax-title,
  .experience-booking__back-btn,
  .experience-booking__price {
    display: none;
  }
}
