.input-with-icon {
  position: relative;

  input,
  select {
    padding-left: 2rem;
  }

  input {
    @extend .form-control;

    &:read-only {
      background-color: transparent;
    }

    &::placeholder {
      color: inherit;
    }
  }

  .input-with-icon__icon {
    position: absolute;
    pointer-events: none;
    right: 0.75rem;
    top: 0.5rem;
    color: $grey;
  }

  .input-with-icon__icon--high {
    top: 0.7rem;
  }

  .input-with-icon__icon--left {
    left: 0.75rem;
    width: fit-content;
  }

  &.input-with-icon--on-right {
    input::placeholder {
      color: $gray-600;
    }

    input,
    select {
      padding-left: 0.75rem;
    }
  }
}
