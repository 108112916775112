.route-stop__tabs {
  @include list-unstyled;

  display: flex;
  border-bottom: solid 1px $divider;
  padding: 1rem 0;

  li {
    margin-bottom: -1px;
  }

  a {
    padding: 1rem 1.5rem;
    border-bottom: solid 2px transparent;
    text-decoration: none;
    color: $text-black;

    &.active,
    &:hover {
      color: $primary;
      border-color: $primary;
    }
  }
}

.route-stops__list {
  @include list-unstyled;

  margin: 2rem 0;
  position: relative;
  padding-left: 2rem;

  @include media-breakpoint-up(md) {
    max-width: 50%;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0.25rem;
    bottom: 0.5rem;
    left: 0.75rem;
    width: 0.25rem;
    background-color: $primary;
  }
}

.route-stops__stop {
  position: relative;
  margin-bottom: 0.75rem;

  &::before {
    content: "";
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 0.25rem;
    left: -1.65rem;
    border: solid 2px $primary;
    border-radius: 50%;
    background-color: $white;
  }
}

.route-stops__stop-description {
  display: block;
  margin: 0.25rem 0 1rem;
  color: $text-grey;

  @include text-small;
}
