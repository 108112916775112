// =========================================================================
// Images should be block level to remove unsued space below them
// =========================================================================

img {
  display: block;
}

// =========================================================================
// Reset buttons to resemble links instead
// =========================================================================

button {
  -webkit-appearance: inherit;
  -webkit-box-align: inherit;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline;
  font: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  height: auto;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
  border-radius: 0;
  // fix for psuedo elements being misaligned in IE
  overflow: visible;
}

// =========================================================================
// Strip margins from commonly used elements
// =========================================================================

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
table,
figure {
  margin: 0;
}

// ***********************
// Hide input Number field arrows
// ***********************

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* When 2 column, bottom border gets hidden */
.tns-ovh {
  padding-bottom: 1px;
}

.mbsc-calendar-day-empty {
  visibility: hidden;
}
