.animation-momentum-spin {
  animation: momentumSpin 3000ms cubic-bezier(0.79, 0.33, 0.14, 0.53) infinite;
}

@keyframes momentumSpin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
}
