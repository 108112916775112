$formie-gutter: 0.5rem;

.footer-form {
  .fui-form-container {
    .fui-page {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .fui-page-container {
        width: 75%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .fui-row {
          width: 100%;
          margin-bottom: 0 !important;

          --field-min-width: 10rem !important;

          .fui-field-container {
            .fui-input-container,
            .fui-input-wrapper {
              .fui-input {
                @extend .form-control;

                width: 100%;
              }

              .fui-select {
                @extend .form-select;
              }
            }
          }
        }
      }

      .fui-btn-container {
        width: 100%;

        .fui-submit {
          @extend .btn;
          @extend .btn-secondary;

          width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .footer-form {
    .fui-form-container {
      .fui-page {
        flex-direction: row;
        gap: 0.5rem;

        .fui-page-container {
          width: 75%;
          flex-direction: row;
          gap: 0.5rem;
        }

        .fui-btn-container {
          width: 25%;
          padding: 0;
        }

        .fui-field {
          margin-bottom: 0;
        }

        .fui-label {
          color: $text-white;
        }

        .fui-submit {
          margin-top: 1.5rem;
        }

        &:has(.fui-error-message) {
          .fui-submit {
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }
}

.formie-form__wrapper {
  // Labels and legends

  .fui-label {
    @include body-text();
  }

  .fui-legend {
    @include body-text();
  }

  // Inputs

  .fui-input:not(.fui-checkbox-input):not(.fui-radio-input) {
    @extend .form-control;
  }

  .fui-select {
    @extend .form-select;

    overflow-x: hidden;
  }

  .fui-checkbox {
    @extend .form-check;

    .fui-checkbox-input {
      @extend .form-check-input;
    }

    .fui-checkbox-label {
      @extend .form-check-label;
    }
  }

  .fui-radio {
    @extend .form-check;

    .fui-radio-input {
      @extend .form-check-input;
    }

    .fui-radio-label {
      @extend .form-check-label;
    }
  }

  textarea.fui-input {
    min-height: 7rem;
    resize: vertical;
  }

  // Groups

  .fui-group {
    padding: 1.25rem;
    border: $divider solid 1px;
  }

  // Buttons

  .fui-btn {
    @extend .btn, .btn-light;
  }

  .fui-btn.fui-submit {
    @extend .btn, .btn-primary;

    width: 100%;
  }

  .fui-btn-container {
    margin-top: 0.5rem;
  }

  // Alerts and errors

  .fui-required {
    color: $danger;
  }

  .fui-alert {
    @extend .alert;
  }

  .fui-alert-error {
    @extend .alert-danger;
  }

  .fui-error-message {
    color: $danger;

    @include text-x-small;

    margin-top: 0.25rem;
  }

  // Layout

  .fui-row {
    display: flex;
    flex-direction: column;
    margin-left: -$formie-gutter;
    margin-right: -$formie-gutter;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
  }

  .fui-field {
    flex: 1;
    padding-left: $formie-gutter;
    padding-right: $formie-gutter;
    margin-bottom: 1rem;
  }

  .fui-field-rows {
    margin-bottom: -1rem;
  }

  // Misc
  .fui-hidden {
    display: none;
  }
}
