// ***********************
// Colour
// ***********************

@use "sass:color";

// Brilliant travels colors

$bt-charcoal: #414042;

// Alter existing bootstrap colours

$primary: #008fd5 !default;
$secondary: #faa21b !default;
$muted: #999 !default;
$light: #f8f9fa !default;
$grey: #919191 !default;
$white: #fff !default;
$black: #000 !default;

$table-accent-bg: $light;

// Create new colours

$primary-light: color.adjust($primary, $lightness: 15%) !default;
$primary-dark: color.adjust($primary, $lightness: -15%) !default;

$secondary-light: color.adjust($secondary, $lightness: 15%) !default;
$secondary-dark: color.adjust($secondary, $lightness: -15%) !default;

$divider: #dfdfdf !default;

$text-white: $white !default;
$text-black: rgba($black, 0.85) !default;
$text-grey: $muted !default;

$light-dark: color.adjust($light, $lightness: -2.5%) !default;

$white-25: rgba($white, 0.25);

// Alter existing bootstrap colours (dependant on new colours above)

$link-hover-color: $primary-dark !default;

// Extend new colours into bootstraps theme colours to generate utility classes

$custom-colors: (
  "primary-light": $primary-light,
  "primary-dark": $primary-dark,
  "secondary-light": $secondary-light,
  "secondary-dark": $secondary-dark,
  "divider": $divider,
  "white-25": $white-25,
  "grey": $grey,
);

// ***********************
// Breakpoints
// ***********************

$header-breakpoint: 960px;

// ***********************
// Buttons
// ***********************

$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1rem;

// ***********************
// Container
// Reset bootstraps container to a single width
// ***********************

$container-padding-mobile: 1.5rem;
$container-padding-desktop: 3rem;

$container-width: 1180px;
$container-max-widths: (
  xl: $container-width,
);

// ***********************
// Offcanvas
// ***********************

$offcanvas-horizontal-width: 32rem;

// ***********************
// Spacing system
// ***********************

$grid-gutter-width: 2rem;

$spacer: 1rem;

// For some reason these spacing units no not generate negative margin BS utilities

$custom-spacers: (
  5: (
    $spacer * 2,
  ),
  6: (
    $spacer * 2.5,
  ),
  7: (
    $spacer * 3,
  ),
  8: (
    $spacer * 3.5,
  ),
  9: (
    $spacer * 4,
  ),
  10: (
    $spacer * 5,
  ),
);

// ***********************
// Border radius
// ***********************

$border-radius: 0.15rem;
$border-radius-lg: 0.3rem;

// ***********************
// Banner
// ***********************

$banner-mobile-breakpoint: 600px;

// ***********************
// Box shadow
// ***********************

$default-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
