.blog-components__block {
  .text-content__wrapper {
    h2 {
      margin-bottom: 3rem;
      padding-bottom: 1.5rem;
      position: relative;

      &::after {
        content: "";
        width: 6rem;
        height: 0.25rem;
        background-color: $primary;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.blog-preview {
  position: relative;

  @include hoverScaleImage();

  .h4 {
    text-decoration: underline;
  }

  &:hover {
    .h4 {
      text-decoration: none;
    }
  }

  .categories__ul {
    margin-bottom: 0.25rem;

    @include text-x-small;

    a {
      color: $text-black;
      text-decoration: none;
    }
  }
}

.blog-categories__button {
  position: relative;
  top: -1px;
}

.blog-categories__dropdown {
  .dropdown-item {
    text-decoration: none;
  }
}
