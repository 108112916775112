@use "sass:map";

.gdpr-notice {
  background: $primary-dark;
  position: fixed;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  padding: 1rem 0;
  color: $text-white;
  z-index: map.get($zindex, gdpr);

  @include media-breakpoint-up(md) {
    left: auto;
    bottom: 1rem;
    right: 1rem;
    padding: 1.5rem 0;
    max-width: 60%;
  }

  @include media-breakpoint-up(lg) {
    max-width: 35%;
  }
}
