.booking-history {
  background-color: $light;

  .booking-history__group {
    background-color: $white;
    padding: 1.5rem;
    border: $divider solid 1px;
    box-shadow: $default-shadow;
    border-radius: $border-radius;
  }

  .booking-history__pagination,
  .btn-link {
    color: $secondary;
  }

  .booking-history__pagination--disabled {
    .fa-regular {
      opacity: 0.65;
      color: $gray-600;
    }

    .btn-link {
      color: $gray-600;
    }
  }
}

.booking-history.sealink {
  .booking-history__header {
    background-color: $white;
  }
}
