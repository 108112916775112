.product-option {
  border-left: solid 3px $primary;
}

.product-option__content {
  flex: 1 1 auto;
  padding: 1.75rem;
}

.product-option__image {
  flex: 0 0 12.5rem;
}
