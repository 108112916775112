html,
body {
  height: 100%;
}

.layout__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.layout__content {
  flex-grow: 1;
  flex-shrink: 0;
}

// Container variables

$container-padding: 1rem;
$site-width: 1200px;

// Card layout

.layout__cards,
.layout__simple-tiles {
  margin-bottom: $grid-gutter-width * -1;

  > *[class^="col"] {
    margin-bottom: $grid-gutter-width;
  }
}

// Columns layout

.layout__columns {
  margin-bottom: 3.5rem * -1;

  > *[class^="col"] {
    margin-bottom: 3.5rem;
  }
}
