$nav-pseudo-element-colour: $white !default;
$nav-pseudo-element-opacity: 0.25 !default;
$nav-dropdown-chevron: $white !default;
$nav-desktop-default-font-size: 1rem !default;
$nav-desktop-margin-gap: 1.25rem !default;

.nav__main--desktop {
  & > ul {
    padding-left: $grid-gutter-width;
    display: flex;
    align-items: center;
    margin-bottom: 0;

    // Level 1
    & > li {
      position: relative;

      & + li {
        margin-left: $nav-desktop-margin-gap;
      }

      & > a,
      & > button {
        padding: 2rem 0;
        position: relative;
        display: flex;
        align-items: center;
        transition: all 0.15s ease;
        font-size: $nav-desktop-default-font-size;

        .header--shrink & {
          padding: 1.25rem 0;

          @include text-small;
        }

        &::before {
          position: absolute;
          height: 4px;
          background: $nav-pseudo-element-colour;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0;
          transition: opacity 0.25s;
          content: "";
        }

        &:hover::before,
        &.active::before {
          opacity: $nav-pseudo-element-opacity;
        }

        &.has-subnav {
          &::after {
            font: var(--fa-font-solid);
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            content: "\f078";
            margin-left: 0.5rem;
            font-size: 0.75em;
            color: $nav-dropdown-chevron;
          }
        }
      }

      // Level 2
      & > ul {
        &.dropdown-menu {
          margin: 0;
          width: 15rem;
          border-radius: 0 0 $border-radius $border-radius;
        }
      }
    }
  }
}

.nav-mobile {
  background: $white;

  a,
  button {
    display: block;
    text-decoration: none;
    width: 100%;
    color: $text-black;
    padding: 0.75rem $container-padding;
    border-bottom: 1px solid $divider;

    &.has-subnav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &::after {
        color: $primary;
        font: var(--fa-font-solid);
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        content: "\f078";
        font-size: 0.75em;
      }

      &:not(.collapsed) {
        &::after {
          content: "\f077";
        }
      }
    }
  }
}

.nav-mobile__main {
  // Level 1
  & > li > a,
  & > li > button {
    font-weight: 700;
  }

  // Level 2
  & > li > ul {
    background-color: $light;
  }

  & > li > ul > li > a,
  & > li > ul > li > button {
    padding-left: $container-padding * 1.5;
  }

  // Level 3
  & > li > ul > li > ul > li > a,
  & > li > ul > li > ul > li > button {
    padding-left: $container-padding * 2;
  }
}

@include media-breakpoint-down(lg) {
  .nav-user {
    border-top: $divider solid 3px;
  }
}

// BS nav

.nav-link {
  text-decoration: none;
}

.nav-pills {
  background: $light;
  padding: 0.25rem;
}
