@each $name, $unit in $spacers {
  // Vertical spacing
  .space-y-#{$name} {
    > * + * {
      margin-top: $unit;
    }
  }
  // Horizontal spacing
  .space-x-#{$name} {
    > * + * {
      margin-left: $unit;
    }
  }
}
