@use "sass:math";

.embed-content__wrapper {
  iframe {
    width: 100%;
  }
}

.embed-content--two-three {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: math.div(2, 3) * 100%;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
