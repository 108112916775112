@use "sass:map";

.banner {
  color: $text-white;
}

// Banner link

.banner--full-screen-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
}

// Banner treatments

.banner--treatment-darken-image {
  text-shadow: 0 0 8px rgba($black, 0.3);

  &::after {
    content: "";
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    pointer-events: none;
  }
}

.banner--treatment-box-content {
  .banner__content {
    background: rgba($black, 0.5);
    padding: 2rem;
    border-radius: $border-radius;
  }
}

// Banner height

.banner__height {
  width: 100%;
  position: relative;
  display: block;
  overflow: hidden;

  // Ultrawide
  @include aspect-ratio(700, 2400);

  // Desktop
  @media screen and (max-width: 1900px) {
    @include aspect-ratio(700, 1900);
  }

  // Tablet
  @media screen and (max-width: 1100px) {
    @include aspect-ratio(700, 1100);
  }

  // Mobile
  @media screen and (max-width: $banner-mobile-breakpoint) {
    @include aspect-ratio(700, 600);
  }
}

// Banner image

.banner_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// Banner videos

.banner__video {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media screen and (min-width: $banner-mobile-breakpoint) {
      display: none;
    }
  }

  video {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media screen and (max-width: $banner-mobile-breakpoint) {
      display: none;
    }
  }
}

// Banner content

.banner__content-positioner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: map.get($zindex, banner-content);
}

.banner__content-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: $banner-mobile-breakpoint) {
    &--desktop-left {
      align-items: flex-start;
    }

    &--desktop-centre {
      text-align: center;
    }

    &--desktop-right {
      align-items: flex-end;
      text-align: right;
    }
  }

  @media screen and (max-width: $banner-mobile-breakpoint) {
    text-align: center;

    &--mobile-bottom {
      justify-content: flex-end;
    }

    &--mobile-centre {
      justify-content: center;
    }

    &--mobile-top {
      justify-content: flex-start;
    }
  }
}

.banner__content {
  max-width: 540px;

  *:last-child {
    margin-bottom: 0;
  }
}
